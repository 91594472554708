.award-text {
  max-height: 180px;
  overflow: auto;
  text-align: justify;
}
.select2-selection.select2-selection--single,
.select2-selection.select2-selection--multiple {
  display: block;
  width: 100% !important;
  height: calc(100% - 30px) !important;
  font-size: 0.813rem !important;
  line-height: 1.5 !important;
  outline: initial !important;
  background: #f8f9fa;
  border: 1px solid #ced4da !important;
  color: #47404f;
}

.select2.select2-container.select2-container--default,
.select2.select2-container.select2-container--default.select2-container--above {
  height: 100%;
  max-height: 100% !important;
}
.select2.select2-container.select2-container--default.select2-container--below {
  height: 100%;
  max-height: 100% !important;
}
.select2.select2-container.select2-container--default,
.select2.select2-container.select2-container--default.select2-container--focus {
  width: 100% !important;
}

.user-profile .user-info {
  margin-top: 30px !important;
}

#profileTabContent i {
  color: #6494de;
}

.bg-light-gold {
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.bg-light-green {
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.bg-light-grey {
  background-color: #ebebeb !important;
}

.text-gold {
  color: #8a6d3b;
}

.text-green {
  color: #3c763d;
}

div.the_wheel {
  background-image: url(images/wheel-game/wheel_back.png);
  background-position: center -2px;
  background-repeat: no-repeat;
}

.clickable {
  cursor: pointer;
}

#spin_button {
  cursor: pointer;
  cursor: hand;
}

@media (min-width: 992px) {
  .controles {
    margin-top: 250px;
  }
}

.wheel-container {
  max-height: 582px;
  max-width: 610px;
  overflow: auto;
}

.text-black {
  color: #58515f;
}

.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active .nav-item-hold {
  color: #f16246;
}
.layout-sidebar-large .sidebar-left .navigation-left .nav-item.active .triangle {
  border-color: transparent transparent #f16246 transparent;
}

.layout-sidebar-large .main-header .menu-toggle div {
  background-color: white;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: left;
  background: #f8f9fa;
  border: 1px solid #eee;
  border-radius: 20px;
  position: relative;
  width: 100%;
}

.search-bar input {
  background: transparent;
  border: 0;
  color: #212121;
  font-size: 0.8rem;
  line-height: 2;
  height: 100%;
  outline: initial !important;
  padding: 0.5rem 1rem;
  width: calc(100% - 32px);
}

.search-bar .search-icon {
  font-size: 18px;
  width: 24px;
  display: inline-block;
}

.MuiButtonBase-root.MuiIconButton-root {
  outline: 0;
}

body.no-expanded-body {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding-right: 0 !important;
}

.switch-label > label {
  font-size: 14px;
}

.switch-label.active > label {
  font-weight: bold;
}

.invalid-feedback {
  display: block !important;
}

.extra-label {
  font-size: 12px !important;
  font-weight: normal !important;
  font-style: italic;
}
